// Vue
import { createApp } from 'vue';
import App from '@/App.vue';

// Vendors
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

// import vClickOutside from 'v-click-outside';
//import vClickOutside from 'v-click-outside'
import 'bootstrap';

//import VueAnalytics from 'vue-analytics';
//import VueGtag from "vue-gtag-next";

// Base & CSS
import base from "@/base.js";
import '@/assets/custom.scss'

import router from '@/router';
import store from '@/store';

import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

//import CKEditor from '@ckeditor/ckeditor5-vue';
import vClickOutside from '@/tools/clickoutside.js';

// Mitt (bus event)
import mitt from 'mitt';
const emitter = mitt();

library.add(fas, far, fab);

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.component('fa', FontAwesomeIcon);
app.mixin(base);

// TEMPORARY SOLUTION FOR V-CLICK-OUTSIDE
// app.use(vClickOutside);
// source https://github.com/ndelvalle/v-click-outside/issues/238#issuecomment-713872610
//app.directive("v-click-outside", {
//    beforeMount: function (el, binding) {
//        // Define ourClickEventHandler
//        const ourClickEventHandler = event => {
//            if (!el.contains(event.target) && el !== event.target) {
//                // as we are attaching an click event listern to the document (below)
//                // ensure the events target is outside the element or a child of it
//                binding.value(event); // before binding it
//            }
//        };
//        // attached the handler to the element so we can remove it later easily
//        el.__vueClickEventHandler__ = ourClickEventHandler;
//
//        // attaching ourClickEventHandler to a listener on the document here
//        document.addEventListener("click", ourClickEventHandler);
//    },
//    unmounted: function (el) {
//        // Remove Event Listener
//        document.removeEventListener("click", el.__vueClickEventHandler__);
//    }
//});
app.directive('clickOutside', vClickOutside)

app.use(VueFlatPickr);
//app.use(CKEditor);
//app.use(VueGtag, { property: [{ id: 'G-JC7VVDVVTK' }, { id: 'UA-189400010-2' }] });
app.use(store);
app.use(router);
app.mount('#app');
