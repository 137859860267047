import { mapActions, mapState } from "vuex"

export default {
    data(){return {
        companyUid: null,
    }},
    props: {

    },
    created(){
        if(this.$route.params.companyUid){
            this.companyUid = this.$route.params.companyUid;
        }
    },
    computed: {
        ...mapState('Company', ['currentCompany', 'Scompanies', 'Lcompanies', 'Bcompanies', 'B2companies', 'Bstats', 'B2stats', 'Bscompanies', 'B2scompanies']),
    },
    methods: {
        ...mapActions('Company', [
            'getCompany',
            'searchCompanies',
            'listCompanies',
            'benchCompanies',
            'bench2Companies',
            'nextCompanies',
            'next2Companies',
            'previousCompanies',
            'previous2Companies',
            'statsCompanies',
            'stats2Companies',
            'companiesReset',
        ]),
        siren(siren){
            return siren?.substring(0, 9).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' ') || null;
        }
    }
}
