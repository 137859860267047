export default 
[
    {
        path: 'list/',
        name: 'person-list',
        component: () => import('@/views/person/List.vue') 
    },
    {
        path: 'detail/:personUid/',
        name: 'person-detail',
        component: () => import('@/views/person/Detail.vue') 
    },
];