<template>
<div>
    <router-view :key="$route.params.query" />
</div>
</template>

<script>
export default {
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push('/');
    }
  },
};
</script>
