<template>
  <div class="item">
    <div class="container-item d-flex align-items-center p-2">
      <div class="container-image" v-if="imageUrl">
        <div class="image" :style="cssImageUrl(imageUrl)" />
      </div>
      <div class="container-header flex-fill px-2">
        <slot></slot>
      </div>
      <div>
        <fa :icon="icon" :key="icon" />
      </div>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: { type: String },
    icon: { type: String, default: "chevron-right" },
  },
};
</script>

<style lang="scss">
@import "@/assets/vars.scss";
.item {
  color: $primary;
  border-radius: 7px;
  > .container-item {
    height: 60px;

    > .container-image {
      > .image {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: $white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    > .container-header {
      font-size: 1em;
      h2 {
        font-size: 1.4em;
      }
    }
  }
}

.item.small {
  > .container-item {
    > .container-image {
      > .image {
        border-radius: 50%;
        width: 40px;
        height: 40px;
      }
    }
    > .container-header {
      font-size: 1em;

      h2 {
        font-size: 1.4em;
      }
    }
  }
}
</style>
