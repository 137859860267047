<template>
  <div id="main-menu" class="text-capitalize">
    <div
      class="menu-container d-flex align-items-center"
    >
      <router-link to="/" class="logo">
        <img class="logoBD" src="@/assets/logoBoardData.png" />
        <img class="logoScalens" src="@/assets/logoScalens.png" />
      </router-link>
      <div class="nav-buttons d-flex text-center">
        <Benchmark class="nav-menu" v-if="isAuthenticated" />
        <Library class="nav-menu" v-if="isAuthenticated" />
      </div>
      <div class="global-search d-lg-block d-none p-2" v-if="isAuthenticated">
        <form
          @submit.prevent
          class="d-flex align-items-center px-2"
          v-click-outside="reset"
        >
          <input type="text" class="mx-2 fs-5" v-model="search" />
          <fa icon="search" />
        </form>
      </div>
      <div class="user-menu-container d-flex text-center align-items-center gap-3" v-if="isAuthenticated">
        <!-- render button depending on screen size -->
        <a class="btn btn-primary btn-scalens btn-scalens-lg" 
          :href="scalensAppUrl" 
          role="button">
            <fa icon="home" />
            Scalens Dashboard
        </a>
        <a class="btn btn-primary btn-scalens btn-scalens-md" 
          :href="scalensAppUrl" 
          role="button">
            <fa icon="home" />
            Dashboard
        </a>
        <a class="btn btn-primary btn-scalens btn-scalens-sm" 
          :href="scalensAppUrl" 
          role="button">
            <fa icon="home" />
        </a>
        <User class="user-menu" v-if="isAuthenticated" />
      </div>
      <a class="btn btn-primary btn-scalens btn-scalens-lg" 
        :href="scalensAppUrl" 
        role="button"
        v-else>
          <fa icon="home" />
          Scalens Dashboard
      </a>
    </div>
    <div class="menu-container d-lg-none">
      <div class="global-search p-2">
        <form
          @submit.prevent
          class="d-flex align-items-center px-2"
          v-click-outside="reset"
        >
          <input type="text" class="mx-2 fs-5 flex-fill" v-model="search" />
          <fa icon="search" />
        </form>
      </div>
    </div>
    <Results />
  </div>
  <Modal :show="modalAuth" mitt="close-modal-auth" v-if="!isAuthenticated" >
    <template v-slot:header>{{
      _tr("global.login", "On se connait déjà?")
    }}</template>
    <template v-slot:default>
      <Authentication />
    </template>
  </Modal>
</template>

<script>
import Benchmark from "@/components/menu/Benchmark.vue";
import Library from "@/components/menu/Library.vue";
import User from "@/components/menu/User.vue";
import company from "@/components/company/company.js";
import person from "@/components/person/person.js";
import Results from "@/components/menu/Results.vue";
import Modal from "@/tools/Modal.vue";
import Authentication from "@/components/user/Authentication.vue";

export default {
  data() {
    return {
      modalAuth: false,
    };
  },
  mixins: [company, person],
  components: {
    Benchmark,
    Library,
    User,
    Results,
    Modal,
    Authentication,
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.emitter.on("close-modal-auth", () => {
        this.modalAuth = false;
      });
    }
  },
  unmounted() {
    this.emitter.all.delete("close-modal-auth");
  },
  computed: {
    search: {
      get() {
        return this.newvalue;
      },
      set(value) {
        this.newvalue = value;
        if (this.newvalue) {
          this.searchCompanies({ params: { search: value } });
          this.searchPersons({ params: { search: value } });
        } else {
          this.reset();
        }
      },
    },
    scalensAppUrl() {
      return process.env.VUE_APP_SCALENS_APP_URL;
    },
  },
  methods: {
    closeModal() {
      this.modalAuth = false;
      location.reload();
    },
    reset() {
      this.newvalue = null;
      this.companiesReset("SEARCH");
      this.personsReset("SEARCH");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/vars.scss";

#main-menu {
  background-color: $primary;
  color: $white;
  font-size: 15px;
  padding-right: 10px;
  
  > .menu-container {
    gap: 30px;
    position: relative;
    justify-content: space-between;
        
    @media (max-width: 595px) {
      gap: 15px;
    }
    
    > .logo > .logoBD {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-left: 20px;
      max-width: 200px;

      @media (max-width: 630px) {
        display: none;
      }
    }
    
    > .logo > .logoScalens {
      display: none;
      
      @media (max-width: 630px) {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 20px;
        max-width: 35px;
      }
    }

    > .nav-buttons {
      @media (max-width: 1128px) {
        gap: 5px;
        flex-grow: 2;
      }

      @media (max-width: 595px) {
        gap: 0;
      }
    }

    > .nav-buttons > .nav-menu {
      cursor: pointer;
      height: 60px;
      line-height: 60px;
      width: 140px;
      z-index: 5;
      
      @media (max-width: 1177px) {
        width: 120px;
      }

      @media (max-width: 890px) {
        width: 110px;
      }

      @media (max-width: 595px) {
        width: 100px;
      }
      
      > nav {
        border-radius: 0 0 10px 10px;
        background-color: $primary;
        min-width: 170px;
        translate: -17px;
        margin-top: -1px; // fix gap on mobile
        
        @media (max-width: 1177px) {
          translate: -25px;
        }

        @media (max-width: 595px) {
          translate: -40px;
        }
      }
      
      > nav > a {
        border-radius: 15px;
        background-color: $primary;
        display: block;
        color: $white;
        text-decoration: none;
      }
      > nav > a:hover {
        color: $light-blue;
      }
    }

    .btn-scalens {
      background-color: #1ABC9C;
      font-size: 15px;
      text-wrap: nowrap;
      border: none;
    }

    .user-menu-container {
      @media (max-width: 992px) {
        flex-grow: 2;
        justify-content: flex-end;
      }

      @media (max-width: 1270px) {
        .btn-scalens-lg {
          display: none;
        }
      }

      @media (min-width: 1270px) {
        .btn-scalens-md {
          display: none;
        }
      }
      
      @media (min-width: 993px) and (max-width: 1140px) {
        .btn-scalens-md {
          display: none;
        }
      }

      @media (min-width: 627px) and (max-width: 710px) {
        .btn-scalens-md {
          display: none;
        }
      }
      
      @media (max-width: 483px) {
        .btn-scalens-md {
          display: none;
        }
      }
      
      @media (min-width: 1140px) {
        .btn-scalens-sm {
          display: none;
        }
      }

      @media (min-width: 710px) and (max-width: 993px) {
        .btn-scalens-sm {
          display: none;
        }
      }
      
      @media (min-width: 483px) and (max-width: 627px) {
        .btn-scalens-sm {
          display: none;
        }
      }
      
      @media (max-width: 407px) {
        .btn-scalens-sm {
          display: none;
        }
      }
    }
    
    /* Same as .nav-menu but different widths */
    .user-menu {
      cursor: pointer;
      height: 60px;
      line-height: 60px;
      z-index: 5;
      
      > nav {
        border-radius: 0 0 10px 10px;
        background-color: $primary;
        min-width: 150px;
      }
      
      > nav > a {
        border-radius: 15px;
        background-color: $primary;
        display: block;
        color: $white;
        text-decoration: none;
      }
      > nav > a:hover {
        color: $light-blue;
      }
    }

    > .account-type {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
    }
  }

  .global-search {
    background-color: $primary;
    width: 100%;

    @media (max-width: 1128px) {
      width: fit-content;
    }
    
    @media (max-width: 992px) {
      display: none;
    }

    > form {
      background-color: $white;
      border-radius: 15px;
      color: $primary;
      padding: 3px;
      width: 275px;
      
      > input {
        outline: none;
        border: 0;
        color: $primary;
        width: 225px;
      }
    }
  }
  
  .login-button {
    cursor: pointer;
    width: 80px;
    margin-right: 15px;
    position: absolute;
    right: 5px;
    bottom: 15px;

    @media (max-width: 595px) {
      bottom: 12px;
    }
  }
}

</style>
