<template>
    <div class="alert fade show rounded-0" :class="[alertClass(), {'alert-dismissible': closable}]">
        <div class="d-flex align-items-center">
            <fa :icon="icon" v-if="icon" />&nbsp;
            <p class="m-0"><slot></slot></p>
        </div>
        <button class="btn-close" v-if="closable" @click="doIt()"></button>
    </div>
</template>

<script>
export default {
    data(){ return {
        show: true,
    }},
    props: {
        icon: { type: String },
        closable: { type: Boolean }
    },
    methods:{
        alertClass(){
            return this.lvl ? 'alert-'+this.lvl : 'alert-primary'
        },
    }
}
</script>

<style lang="scss">
@import "@/assets/vars.scss";

</style>