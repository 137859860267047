import { createStore } from "vuex";
//import App from '@/tools/store.js';
import User from "@/components/user/store.js";
import Home from "@/components/home/store.js";
import Company from "@/components/company/store.js";
import Person from "@/components/person/store.js";
import Assembly from "./components/assembly/store/assembly.js";
import Resolution from "./components/assembly/store/resolution.js";
import Mandate from "./components/mandate/store";
import Crisis from "./components/crisis/store";

// Create a new store instance.
const store = createStore({
  modules: {
    User,
    Home,
    Company,
    Person,
    Assembly,
    Resolution,
    Mandate,
    Crisis,
  },
});

export default store;
