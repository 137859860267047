<template>
    <Item class="company-item" :class="{small: small}" :imageUrl="company.image_url">
        <component class="m-0" :is="tag">
            <span>{{ company.denomination }}&nbsp;</span>
            <small v-if="company.since">({{ company.since }})</small>
        </component>
        <span v-if="company.siege_fr.siret">{{ siren(company.siege_fr.siret) }}</span>
        <span v-if="company.siege_fr.index">&nbsp;({{ company.siege_fr.index }})</span>
    </Item>
</template>

<script>
import Item from '@/tools/Item.vue';
import company from "@/components/company/company.js";

export default {
    mixins: [company],
    components: {
        Item
    },
    props: {
        company:  { type: Object, required: true },
        tag:     { type: String, default: 'h2' },
        small:   { type: Boolean },
    },
}
</script>

<style lang="scss">
@import "@/assets/vars.scss";
</style>
