<template>
    <router-view :key="$route.params.companyUid"/>
</template>

<script>
export default {
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push('/');
    }
  },
};
</script>
