<template>
  <div class="px-3">
    <form @submit.prevent="sendCode" class="user-auth-form" v-if="step == 1">
      <Message lvl="danger" v-if="error" action="resetError" closable>
        {{
          _tr(
            "user.login_error",
            "Nous n'avons pas réussi a envoyer un code a cette adresse",
          )
        }}
      </Message>
      <div class="input-label">Email</div>
      <input
        class="form-control"
        type="text"
        v-model="emailOrPhone"
        style="color: #001756; font-size: 15px;"
      />
      <div class="container w-100 p-0 mt-3">
        <div class="row btn-row">
          <div class="btn-col col-4">
            <a 
              class="btn btn-primary w-100 border-0" 
              href="https://scalens.com/offers/" 
              target="_blank" 
              style="background-color: #1ABC9C; height: 40px; text-align: center; line-height: 28px"
            >
              <span>{{ _tr("user.signup", "Inscription") }}</span>
            </a>
          </div>
          <div class="btn-col col-8">
            <button 
              class="btn btn-primary w-100 border-0" 
              type="submit" 
              style="height: 40px; text-align: center; line-height: 28px"
            >
              <span>{{ _tr("user.connection", "Connexion") }}</span>
            </button>
          </div>
        </div>
      </div>
    </form>
    <form @submit.prevent="connection" class="user-auth-form" v-else>
      <Message lvl="success">{{
        _tr(
          "user.success_sendcode",
          "Nous vous avons envoyé un code à l'adresse suivante: ",
        )
      }}</Message>
      <Message lvl="danger" v-if="error" action="resetError" closable>{{
        _tr("global.code_empty", "Veuillez remplir le champs ci-dessous.")
      }}</Message>
      <div class="input-label">{{_tr('user.code', 'code')}}</div>
      <input
        class="form-control"
        type="text"
        v-model="codeReceived"
        style="color: #001756; font-size: 15px;"
      />
      <div class="text-end mt-2">
        <button class="btn btn-primary" @click="step = 1">
          <fa icon="backward" /><span>
            &nbsp;{{ _tr("user.back", "Retour") }}</span
          >
        </button>
        <button class="btn btn-primary ms-2">
          <fa icon="sign-in-alt" /><span>
            &nbsp;{{ _tr("user.submit", "Envoyer") }}</span
          >
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import user from "@/components/user/user.js";
import Message from "@/tools/Message.vue";

export default {
  mixins: [user],
  components: {
    Message,
  },
  data() {
    return {
      emailOrPhone: null,
      codeSend: false,
      codeReceived: null,
      response: null,
      error: false,
      step: 1,
    };
  },
  methods: {
    resetError() {
      this.error = false;
    },
    async sendCode() {
      try {
        await this.codeUser({ params: { identity: this.emailOrPhone } });
        this.error = false;
        this.step = 2;
      } catch (error) {
        this.error = true;
      }
    },
    async connection() {
      try {
        await this.obtainToken({
          email: this.emailOrPhone,
          password: this.codeReceived,
        });
        this.error = false;
      } catch (error) {
        this.error = true;
      }
      let updatedLocalStorage = false;
      try {
        if (this.isAuthenticated) {
          await this.getProfile();
          this.$router.push('/home');
        }
      } catch (error) {
        console.error("Failed to retrieve user profile.")
        this.logout();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/vars.scss";

.user-auth-form > input {
  color: #D8D8DF;
  outline: none;
  border: 2px solid #D8D8DF;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 10px;
}

.input-label {
  color: $primary;
  font-size: 15px;
}

.btn-row {
  @media (max-width: 408px) {
    display: flex;
    flex-direction: column;
    gap: 7px;
    
    > .btn-col {
      width: 100%;
    }
  }

}
</style>
