import { createRouter, createWebHistory } from 'vue-router';
//import { trackRouter } from "vue-gtag-next";

import Login from '@/views/login/Login.vue';
import Home from '@/views/home/Home.vue';

import Company from '@/components/company/router';
import CompanyVue from '@/views/company/Company.vue';

import Person from '@/components/person/router';
import PersonVue from '@/views/person/Person.vue';

import Assembly from '@/components/assembly/router';
import AssemblyVue from '@/views/assembly/Assembly.vue';

import Mandate from '@/components/mandate/router';
import MandateVue from '@/views/mandate/Mandate.vue';

import Crisis from '@/components/crisis/router';
import CrisisVue from '@/views/crisis/Crisis.vue';


const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
    },
    {
        path: '/company',
        component: CompanyVue,
        children: Company
    },
    {
        path: '/person',
        component: PersonVue,
        children: Person
    },
    {
        path: '/assembly',
        component: AssemblyVue,
        children: Assembly
    },
    {
        path: '/mandate',
        component: MandateVue,
        children: Mandate
    },
    {
        path: '/crisis',
        component: CrisisVue,
        children: Crisis
    },
]

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: "active",
    linkExactActiveClass: "exactive",
    base: process.env.BASE_URL,
    routes
})

//import http from "@/api";
//router.beforeEach((to, from, next) => {
//    var uid = from.params.uid;
//    if (uid) {
//        http.get('/api/tenant/' + uid + '/current/');
//    }
//    next();
//});

//trackRouter(router);
export default router;
