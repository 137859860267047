import http from "@/api";

const state = {
  endpoint: "/api/resolution/",
  dataCSV: {},
  currentResolution: {},
  Sresolutions: {},
  Lresolutions: {},
  Bresolutions: {},
  B2resolutions: {},
  Bstats: {},
  B2stats: {},
  Btypestats: [],
  B2typestats: [],
};

const mutations = {
  ["RESOLUTION"](state, resolution) {
    state.currentResolution = resolution;
  },
  ["SEARCH"](state, resolutions) {
    state.Sresolutions = resolutions;
  },
  ["LIST"](state, resolutions) {
    state.Lresolutions = resolutions;
  },
  ["BENCHMARK"](state, resolutions) {
    state.Bresolutions = resolutions;
  },
  ["BENCHMARK2"](state, resolutions) {
    state.B2resolutions = resolutions;
  },
  ["STATS"](state, stats) {
    state.Bstats = stats;
  },
  ["STATS2"](state, stats) {
    state.B2stats = stats;
  },
  ["TYPESTATS"](state, stats) {
    state.Btypestats = stats;
  },
  ["TYPESTATS2"](state, stats) {
    state.B2typestats = stats;
  },
  ["DATACSV"](state, data) {
    state.dataCSV = data;
  },
};

const getters = {
  getStatsTypeMerge: (state) => {
    var typeStats = [];
    state.Btypestats.forEach((rtype) => {
      typeStats.push({
        code: rtype.code,
        title: rtype.title,
        Bscore: rtype.avg_score,
        Bcount: rtype.count_score,
      });
    });
    state.B2typestats.forEach((rtype) => {
      var index = typeStats.findIndex((ts) => ts.code === rtype.code);
      if (index > -1) {
        typeStats[index].B2score = rtype.avg_score;
        typeStats[index].B2count = rtype.count_score;
        typeStats[index].B3score = rtype.avg_scenario_two;
        typeStats[index].B3count = rtype.count_scenario_two ;
      } else {
        typeStats.push({
          code: rtype.code,
          title: rtype.title,
          B2score: rtype.avg_score,
          B2count: rtype.count_score,
          B3score: rtype.avg_scenario_two ,
          B3count: rtype.count_scenario_two ,
        });
      }
    });
    return typeStats;
  },
};

const actions = {
  async getResolution({ state, commit }, uid) {
    const response = await http.get(state.endpoint + uid + "/");
    commit("RESOLUTION", response.data);
    return response;
  },

  async getResolutions({ state }, payload) {
    const response = await http.get(state.endpoint, payload);
    return response;
  },
  async getStats({ state }, payload) {
    const response = await http.get(state.endpoint + "stats/", payload);
    return response;
  },
  async getStatsFree({ state }, payload) {
    const response = await http.get(state.endpoint + "stats/free/", payload);
    return response;
  },
  async getFullResolutions({ state }, payload) {
    const response = await http.get(state.endpoint, payload);
    return response;
  },

  async searchResolutions({ commit, dispatch }, payload) {
    const response = await dispatch("getResolutions", payload);
    commit("SEARCH", response.data);
    return response;
  },
  async listResolutions({ commit, dispatch }, payload) {
    const response = await dispatch("getFullResolutions", payload);
    commit("LIST", response.data);
    return response;
  },
  async benchResolutions({ commit, dispatch }, payload) {
    const response = await dispatch("getFullResolutions", payload);
    commit("BENCHMARK", response.data);
    return response;
  },
  async bench2Resolutions({ commit, dispatch }, payload) {
    const response = await dispatch("getFullResolutions", payload);
    commit("BENCHMARK2", response.data);
    return response;
  },
  async nextResolutions({ state, commit }) {
    if (state.Bresolutions.next) {
      const response = await http.get(state.Bresolutions.next);
      commit("BENCHMARK", response.data);
      return response;
    }
  },
  async next2Resolutions({ state, commit }) {
    if (state.B2resolutions.next) {
      const response = await http.get(state.B2resolutions.next);
      commit("BENCHMARK2", response.data);
      return response;
    }
  },
  async previousResolutions({ state, commit }) {
    if (state.Bresolutions.previous) {
      const response = await http.get(state.Bresolutions.previous);
      commit("BENCHMARK", response.data);
      return response;
    }
  },
  async previous2Resolutions({ state, commit }) {
    if (state.B2resolutions.previous) {
      const response = await http.get(state.B2resolutions.previous);
      commit("BENCHMARK2", response.data);
      return response;
    }
  },
  async statsResolutions({ commit, dispatch }, payload) {
    const response = await dispatch("getStats", payload);
    await dispatch("typeStatsResolutions", payload);
    commit("STATS", response.data);
    return response.data;
  },
  async stats2Resolutions({ commit, dispatch }, payload) {
    const response = await dispatch("getStats", payload);
    await dispatch("typeStats2Resolutions", payload);
    commit("STATS2", response.data);
    return response.data;
  },
  resolutionsReset({ commit }, mut) {
    commit(mut, {});
  },

  async getTypeStats({ state }, payload) {
    const response = await http.get(state.endpoint + "typestats/", payload);
    return response;
  },
  async getTypeStatsFree({ state }, payload) {
    const response = await http.get(
      state.endpoint + "typestats/free/",
      payload,
    );
    return response;
  },
  async typeStatsResolutions({ commit, dispatch }, payload) {
    const response = await dispatch("getTypeStats", payload);
    commit("TYPESTATS", response.data);
    return response;
  },
  async typeStats2Resolutions({ commit, dispatch }, payload) {
    const response = await dispatch("getTypeStats", payload);
    commit("TYPESTATS2", response.data);
    return response;
  },
  async csvResolutions({ commit, state }, payload) {
    var url = state.endpoint + "csv/";
    console.log(url, "endpoint");
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
  async csv2Resolutions({ commit, state }, payload) {
    var url = state.endpoint + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
