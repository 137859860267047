export default 
[
    {
        path: 'bench-mandatary/',
        name: 'bench-mandatary',
        component: () => import('@/views/mandate/Mandatary.vue'),

    },
    {
        path: 'bench-comex/',
        name: 'bench-comex',
        component: () => import('@/views/mandate/Comex.vue') 
    },
    {
        path: 'bench-auditor/',
        name: 'bench-auditor',
        component: () => import('@/views/mandate/Auditor.vue') 
    },
    {
        path: 'auditor/',
        name: 'auditor-list',
        component: () => import('@/views/mandate/AuditorList.vue') 
    },
   
];