<template>
    <div v-click-outside="doUnroll">
        <div @click="doRoll()">
            <span>{{ _tr('global.benchmark', 'Benchmark') }}&nbsp;</span>
            <fa :icon="getCaret(unroll)" :key="unroll"/>
        </div>
        <nav v-if="unroll && profile.groups.includes('corporate')">
            <router-link :to="{name: 'bench-mandatary'}">{{ _tr('global.mandataries', 'Administrateurs') }}</router-link>
            <router-link :to="{name: 'companies-benchmark'}">{{ _tr('global.companies', 'Sociétés') }}</router-link>
            <router-link :to="{name: 'bench-comex'}">{{ _tr('global.comexs', 'Comex') }}</router-link>
            <router-link :to="{name: 'bench-auditor'}">{{ _tr('global.auditors', 'Auditeurs') }}</router-link>
            <router-link :to="{name: 'assemblies-benchmark'}">{{ _tr('global.assemblies', 'Assemblées') }}</router-link>
            <!--<router-link :to="{name: 'crisis-benchmark'}">{{ _tr('global.crisis', 'Crise') }}</router-link>-->
        </nav>
        <nav v-else-if="unroll && !profile.groups.includes('corporate')">
            <router-link :to="{name: 'bench-mandatary'}">{{ _tr('global.mandataries', 'Administrateurs') }}</router-link>
            <router-link :to="{name: 'bench-comex'}">{{ _tr('global.comexs', 'Comex') }}</router-link>
        </nav>
    </div>
</template>

<script>
import user from "@/components/user/user.js";

export default {
  mixins: [user],
};
</script>
