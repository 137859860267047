<template>
  <div class="login-page d-flex h-100 justify-content-evenly align-items-center">
    <div class="bd-logo align-self-center">
      <img :src="logoPath()" alt="Scalens logo" style="width: 400px;"/>
    </div>
    <div class="login-form card p-4">
      <LoginForm class="my-auto" />
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/user/Authentication.vue";
export default {
  components: {
    LoginForm,
  },
  data() {
    return {
    };
  },
  methods: {
    logoPath() {
      return require('@/assets/logoScalens.svg');
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.$router.push('/home');
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/vars.scss";

.login-form {
  width: 600px;
  height: 330px;

  @media (max-width: 620px) {
    width: 100vw;
    padding: 20px;
    border: none;
    border-radius: 0;
  }
}

.bd-logo {
  @media (max-width: 1070px) {
    display: none;
  }
}

</style>
