import axios from 'axios'
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
const token = localStorage.getItem('accessToken');
if (token) axios.defaults.headers.common['Authorization'] = 'Bearer '+ token;

export default axios.create({
    baseURL: "//", // to url base
    headers: {
        "Content-type": "application/json"
    }
})