<template>
  <div
    class="modal-open modal d-block show rounded-0"
    tabindex="-1"
    v-if="show"
  >
    <div class="modal-dialog" :class="[size, dialog]" v-click-outside="doIt">
      <div class="modal-content rounded-3">
        <div class="modal-header">
          <h4 class="modal-title"><slot name="header"></slot></h4>
          <button type="button" class="btn-close" @click="doIt"></button>
        </div>
        <main class="modal-body">
          <slot></slot>
        </main>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" v-if="show"></div>
</template>

<script>
import { mapState } from "vuex";
import Action from "@/tools/Action.vue";

export default {
  components: {
    Action,
  },
  name: "modal",
  computed: { ...mapState("App", ["modal"]) },
  props: {
    show: false,
    dialog: { type: String },
    size : { type: String }
  },
};
</script>

<style lang="scss">
@import "@/assets/vars.scss";

.modal-dialog {
  margin-top: 80px !important;
}

.modal-open {
  margin-top: 65px !important;
}

.modal-large {
  min-width: 60vw;
}

.modal-content {
  font-size: 19px;
  border: 5px solid $panel-color !important;
}

.modal-header > h4 {
  color: $primary;
}
</style>
