<template>
    <component :is="tag" :class="baseClass()" @click="doIt()" v-bind="bindAttr()">
        <slot></slot>
    </component>
</template>

<script>
export default {
    props: {
        tag: { type: String, default: 'div' },
    },
}
</script>