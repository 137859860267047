<template>
  <div class="home d-flex w-100 justify-content-center">
    <div class="home-container card">
      <h2>Welcome {{ profile.first_name }}</h2>
      <div class="nav-container my-4">
        <div 
          class="card nav-card mb-3" 
          v-for="module in accountType" 
          :key="module.name"
          @click="navigateToModule(module.path)"
        >
          <img class="card-img-top" :src="imagePath(module.name)" :alt="`Image of ${module.name} module`" >
          <div class="card-footer d-flex justify-content-between align-items-center">
            <span>{{ module.name }}</span>
            <fa class="fas fa-arrow-right arrow-icon" icon="arrow-right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountType: [],
      corporate: [
        {"name": "Directors", "path": "/mandate/bench-mandatary/"},
        {"name": "ESG", "path": "/company/benchmark/"},
        {"name": "Comp & Ben", "path": "/mandate/bench-comex/"},
        {"name": "Auditors", "path": "/mandate/bench-auditor/"},
        {"name": "AGM", "path": "/assembly/benchmark/"},
        {"name": "Library", "path": "/company/list/"},
      ],
      executive: [
        {"name": "Directors", "path": "/mandate/bench-mandatary/"},
        {"name": "Comp & Ben", "path": "/mandate/bench-comex/"},
        {"name": "Library", "path": "/company/list/"},
      ],
    }
  },
  methods: {
    imagePath(name) {
      return require(`@/assets/images/${name}.png`);
    },
    navigateToModule(modulePath) {
      this.$router.push(modulePath);
    },
  },
  mounted() {
    if (!this.isAuthenticated) {
      this.$router.push('/');
    }
    if (this.profile.groups.includes('corporate')) {
      this.accountType = this.corporate;
     } else {
      this.accountType = this.executive;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/vars.scss";

.home {
  margin: 5% auto;

  @media (max-width: 568px) {
    margin: 0;
  }
}

.home-container {
  width: 85vw;
  max-width: 1350px;
  border-radius: 10px;
  font-family: "Lato";
  padding: 50px 70px;
  background: white;
  
  @media (max-width: 568px) {
    width: 100vw;
    padding: 20px;
    border: none;
    border-radius: 0;
  }

  h2 {
    color: $primary;
    font-size: 1.9em;
  }
}

.nav-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 30px;
}

.nav-card {
  cursor: pointer;
  position: relative;

  &:hover .arrow-icon {
    opacity: 1;
    right: 10px;
  }

  .card-footer {
    background-color: $primary;
    color: white;
    position: relative;
    margin-top: -35px;
  }

  .arrow-icon {
    transition: opacity 0.3s ease;
    opacity: 0;
    position: absolute;
    right: -20px;
  }
}
</style>
