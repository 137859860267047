import axios from "axios";
import { compile } from "vue";
import { mapState, mapGetters, mapActions } from "vuex";
//import ShortUniqueId from 'short-unique-id';
import moment from 'moment';


export default {
    data() {return {
        unroll: false,
        ready: false,
        newvalue: null,
        awaiting: null,
        cancel: undefined,
        results: [],
        method: 'GET',
        timer: 300,
        messages: {
            cancel: "Operation canceled due to new request.",
            unknown: "Error unknown, you can call the support",
            areyousure: "Are you sure to do this?",
        }
    }},
    computed: {
        ...mapState('App', ['images']),
        ...mapState('User',   ['config_', 'tr_', 'profile', 'max']),
        ...mapGetters('User', ["isAuthenticated", "hasRole"]),
    },
    props: {
        // CRUD and MORE
        create:    { type: Boolean, default: false },
        retrieve:  { type: Boolean, default: false },
        update:    { type: Boolean, default: false },
        remove:    { type: Boolean, default: false },
        read:      { type: Boolean, default: false },
        execute:   { type: Boolean, default: false },
        disable:   { type: Boolean, default: false },
        // SHORTCUTS
        recursive: { type: Boolean, default: false },
        mitt:      { type: String },
        action:    { type: String },
        dispatch:  { type: String },
        take:      { type: String },
        mtake:     { type: String },
        give:      { type: String },
        mgive:     { type: String },
        config:    { type: Object },
        params:    { type: Object },
        keep:      { type: Object },
        attrs:     { type: Object, default() { return {} }},
        // CSSRULES
        size: { type: String, default: 'md' },
        lvl:  { type: String },
        lgt:  { type: String },
        drk:  { type: String },

    },
    methods: {
        ...mapActions('User', []),
        thisContext(){ return this; },
        iconLvl(lvl) {
            if(lvl == 'notify')  return 'question';
            if(lvl == 'success') return 'check';
            if(lvl == 'alert')   return 'exclamation';
            if(lvl == 'warning') return 'exclamation-triangle';
            return 'info';
        },
        doRoll(){ this.unroll = !this.unroll; },
        doUnroll() { this.unroll = false; },
        // MODAL/FlyModal
        //...mapActions('App', ["openModal", "openFlyModal"]),
        //useModal(config) { this.openModal(config); },
        openModal(data) {
            this.$store.dispatch("App/openModal", {});
            this[data] = true;
        },
        closeModal() { alert('ok'); this.$store.dispatch("App/closeModal"); },
        //useFlyModal(config) { this.openFlyModal(config); },
        //closeFlyModal() { this.$store.dispatch("App/closeFlyModal"); },
        // CRUD and MORE
        canCreate()   { return this.create; },
        canRetrieve() { return this.retrieve; },
        canUpdate()   { return this.update; },
        canRemove()   { return this.remove; },
        canRead()     { return this.read; },
        canExecute()  { return this.execute; },
        canDisable()  { return this.disable; },
        // SHORTCUTS
        bindAttr() { return this.attrs; },
        hatch()  { return this.recursive ? this.give : ''; },
        mhatch() { return this.recursive ? this.mgive : 'giveIt'; },
        hasToDoIt() { return (this.action || this.dispatch) },
        async doIt(payload) {
            payload = payload !== undefined ? payload : undefined;
            var keep = this.keep;
            if(keep){
                if(this.mitt) this.emitter.emit(this.mitt, {payload, keep});
                if(this.action) return this.$parent[this.action]({ payload, keep });
                if(this.dispatch) return await this.$store.dispatch(this.dispatch, { payload, keep });
            }else{
                if(this.mitt) this.emitter.emit(this.mitt);
                if(this.action) return this.$parent[this.action](payload);
                if(this.dispatch) return await this.$store.dispatch(this.dispatch, payload);
            }
        },
        takeIt(){
            if(this.take) return this.$parent[this.take];
            if(this.mtake) this.$parent[this.mtake]();
        },
        giveIt(data) {
            if(this.keep) {
                if(this.mitt) this.emitter.emit(this.mitt, {data: data, keep: this.keep});
                if(this.give) this.$parent[this.give] = {data: data, keep: this.keep};
                if(this.mgive) this.$parent[this.mgive]({data: data, keep: this.keep});
            } else {
                if(this.mitt) this.emitter.emit(this.mitt, data);
                if(this.give) this.$parent[this.give] = data;
                if(this.mgive) this.$parent[this.mgive](data);
            }
        },
        // CSSRULES
        getLvl() { return this.lvl },
        baseClass() { return [
            this.size,
            this.getLvl(),
        ];},
        // HELPERS
        //getShortUid() { return new ShortUniqueId()(); },
        getCaret(test){ return test ? "caret-up" : "caret-down"; },
        getSmth(smth){ return this[smth] ? smth + this[smth] : ''; },
        cssImageUrl: url => "background-image: url('"+url+"')",
        has: obj => Object.keys(obj).length,
        hasMethod(method){ return (typeof this[method] === "function"); },
        numberReadable(number){ return String(number).replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, ' '); },
        _tr(tr, dflt) {
            var tr_ = this.getDescendantVal(tr, this.tr_);
            if(tr_) {
                return tr_;
            }else{
                // this.$store.dispatch("User/trload", {path: tr, trans: dflt});
                return dflt;
            }
        },
        actionClearable(action, payload) {
            if(this.awaiting) this.awaiting = clearTimeout(this.awaiting);
            setTimeout(() => {
                if(payload)
                    this[action](payload);
                else
                    this[action]();
            },
            this.timer);
        },
        compileTemplate(template, data) {
            template = compile(template);
            return Object.assign({}, template, {data() { return data }})
        },
        getDescendantVal(desc, obj, ifnull) {
            var objc = obj;
            console.log(desc);
            var arr = desc.split(".");
            while(arr.length && (obj = obj[arr.shift()]));
            if(obj === undefined && ifnull) {
                ifnull.forEach(element => {
                    if(!obj) obj = this.getDescendantVal(element+"."+desc, objc);
                });
            }
            return obj;
        },
        payload(Oparams) {
            Oparams = Oparams ? Oparams : this.params;
            if(this.cancel !== undefined) this.cancel.cancel(this.messages.cancel);
            const CancelToken = axios.CancelToken;
            this.cancel = CancelToken.source();
            var payload = Object.assign({}, Oparams);
            payload.cancelToken = this.cancel.token;
            return payload;
        },
        getDateReadable(date){
            if(date){
                moment.locale('fr'); 
                var momentObj = moment(date, 'YYYY-MM-DD');
                return momentObj.format('dddd Do MMMM, YYYY');
            }
            return false;
        }
    }
}
