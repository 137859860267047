export default 
[
    {
        path: 'list/',
        name: 'assembly-list',
        component: () => import('@/views/assembly/List.vue') 
    },
    {
        path: 'detail/:assemblyUid/',
        name: 'assembly-detail',
        component: () => import('@/views/assembly/Detail.vue') 
    },
    {
        path: 'benchmark/',
        name: 'assemblies-benchmark',
        component: () => import('@/views/assembly/Benchmark.vue') 
    },
];