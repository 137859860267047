import http from "@/api";

const state = {
  endpoint: "/api/company/",
  dataCSV: {},
  currentCompany: {},
  Scompanies: {},
  Lcompanies: {},
  Bcompanies: {},
  B2companies: {},
  Bstats: {},
  B2stats: {},
  Bscompanies: {},
  B2scompanies: {},
};

const mutations = {
  ["COMPANY"](state, company) {
    state.currentCompany = company;
  },
  ["SEARCH"](state, companies) {
    state.Scompanies = companies;
  },
  ["LIST"](state, companies) {
    state.Lcompanies = companies;
  },
  ["BENCHMARK"](state, companies) {
    state.Bcompanies = companies;
  },
  ["BENCHMARK2"](state, companies) {
    state.B2companies = companies;
  },
  ["STATS"](state, stats) {
    state.Bstats = stats;
    state.Bscompanies = stats;
  },
  ["STATS2"](state, stats) {
    state.B2stats = stats;
    state.B2scompanies = stats;
  },
  ["DATACSV"](state, data) {
    state.dataCSV = data;
  },
};

const getters = {};

const actions = {
  async getCompany({ state, commit }, uid) {
    const response = await http.get(state.endpoint + uid + "/");
    commit("COMPANY", response.data);
    return response;
  },
  async getCompanies({ state }, payload) {
    const response = await http.get(state.endpoint, payload);
    return response;
  },
  async getStats({ state }, payload) {
    const response = await http.get(state.endpoint + "stats/", payload);
    return response;
  },
  async getStatsFree({ state }, payload) {
    const response = await http.get(state.endpoint + "stats/free/", payload);
    return response;
  },
  async getFullCompanies({ state }, payload) {
    const response = await http.get(state.endpoint + "full/", payload);
    return response;
  },
  async searchCompanies({ commit, dispatch }, payload) {
    const response = await dispatch("getCompanies", payload);
    commit("SEARCH", response.data);
    return response;
  },
  async listCompanies({ commit, dispatch }, payload) {
    const response = await dispatch("getFullCompanies", payload);
    commit("LIST", response.data);
    return response;
  },
  async benchCompanies({ commit, dispatch }, payload) {
    const response = await dispatch("getCompanies", payload);
    commit("BENCHMARK", response.data);
    return response;
  },
  async bench2Companies({ commit, dispatch }, payload) {
    const response = await dispatch("getCompanies", payload);
    commit("BENCHMARK2", response.data);
    return response;
  },
  async nextCompanies({ state, commit }) {
    if (state.Bcompanies.next) {
      const response = await http.get(state.Bcompanies.next);
      console.log(response.data);
      commit("BENCHMARK", response.data);
      return response;
    }
  },
  async next2Companies({ state, commit }) {
    if (state.B2companies.next) {
      const response = await http.get(state.B2companies.next);
      commit("BENCHMARK2", response.data);
      return response;
    }
  },
  async previousCompanies({ state, commit }) {
    if (state.Bcompanies.previous) {
      const response = await http.get(state.Bcompanies.previous);
      commit("BENCHMARK", response.data);
      return response;
    }
  },
  async previous2Companies({ state, commit }) {
    if (state.B2companies.previous) {
      const response = await http.get(state.B2companies.previous);
      commit("BENCHMARK2", response.data);
      return response;
    }
  },
  async statsCompanies({ commit, dispatch }, payload) {
    const response = await dispatch("getStats", payload);
    commit("STATS", response.data);
    return response;
  },
  async stats2Companies({ commit, dispatch }, payload) {
    const response = await dispatch("getStats", payload);
    commit("STATS2", response.data);
    return response;
  },
  companiesReset({ commit }, mut) {
    commit(mut, {});
  },
  async csvCompanies({ commit, state }, payload) {
    var url = state.endpoint + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
  async csv2Companies({ commit, state }, payload) {
    var url = state.endpoint + "csv/";
    var payload = payload != undefined ? payload : {};
    const response = await http.get(url, payload);
    commit("DATACSV", response.data);
    return response.data;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
