import http from "@/api";

const state = {
  endpoint: "/api/assembly/",
  currentAssembly: {},
  Sassemblies: {},
  Lassemblies: {},
  Bassemblies: {},
  B2assemblies: {},
  Bstats: {},
  B2stats: {},
};

const mutations = {
  ["PERSON"](state, assembly) {
    state.currentAssembly = assembly;
  },
  ["SEARCH"](state, assemblies) {
    state.Sassemblies = assemblies;
  },
  ["LIST"](state, assemblies) {
    state.Lassemblies = assemblies;
  },
  ["BENCHMARK"](state, assemblies) {
    state.Bassemblies = assemblies;
  },
  ["BENCHMARK2"](state, assemblies) {
    state.B2assemblies = assemblies;
  },
  ["STATS"](state, stats) {
    state.Bstats = stats;
  },
  ["STATS2"](state, stats) {
    state.B2stats = stats;
  },
};

const getters = {};

const actions = {
  async getAssembly({ state, commit }, uid) {
    const response = await http.get(state.endpoint + uid + "/");
    commit("PERSON", response.data);
    return response;
  },

  async getAssemblies({ state }, payload) {
    const response = await http.get(state.endpoint, payload);
    return response;
  },
  async getStats({ state }, payload) {
    const response = await http.get(state.endpoint + "stats/", payload);
    return response;
  },
  async getStatsFree({ state }, payload) {
    const response = await http.get(state.endpoint + "stats/free/", payload);
    return response;
  },
  async getFullAssemblies({ state }, payload) {
    const response = await http.get(state.endpoint + "full/", payload);
    return response;
  },

  async searchAssemblies({ commit, dispatch }, payload) {
    const response = await dispatch("getAssemblies", payload);
    commit("SEARCH", response.data);
    return response;
  },
  async listAssemblies({ commit, dispatch }, payload) {
    const response = await dispatch("getFullAssemblies", payload);
    commit("LIST", response.data);
    return response;
  },
  async benchAssemblies({ commit, dispatch }, payload) {
    const response = await dispatch("getFullAssemblies", payload);
    commit("BENCHMARK", response.data);
    return response;
  },
  async bench2Assemblies({ commit, dispatch }, payload) {
    const response = await dispatch("getFullAssemblies", payload);
    commit("BENCHMARK2", response.data);
    return response;
  },
  async nextAssemblies({ state, commit }) {
    if (state.Bassemblies.next) {
      const response = await http.get(state.Bassemblies.next);
      commit("BENCHMARK", response.data);
      return response;
    }
  },
  async next2Assemblies({ state, commit }) {
    if (state.B2assemblies.next) {
      const response = await http.get(state.B2assemblies.next);
      commit("BENCHMARK2", response.data);
      return response;
    }
  },
  async previousAssemblies({ state, commit }) {
    if (state.Bassemblies.previous) {
      const response = await http.get(state.Bassemblies.previous);
      commit("BENCHMARK", response.data);
      return response;
    }
  },
  async previous2Assemblies({ state, commit }) {
    if (state.B2assemblies.previous) {
      const response = await http.get(state.B2assemblies.previous);
      commit("BENCHMARK2", response.data);
      return response;
    }
  },
  async statsAssemblies({ commit, dispatch }, payload) {
    const response = await dispatch("getStats", payload);
    commit("STATS", response.data);
    return response;
  },
  async stats2Assemblies({ commit, dispatch }, payload) {
    const response = await dispatch("getStats", payload);
    commit("STATS2", response.data);
    return response;
  },
  assembliesReset({ commit }, mut) {
    commit(mut, {});
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
