import { mapActions, mapState } from "vuex"
import moment from 'moment';

export default {
    data(){return {
        personUid: null,
    }},
    props: {

    },
    created(){
        if(this.$route.params.personUid){
            this.personUid = this.$route.params.personUid;
        }
    },
    computed: {
        ...mapState('Person', ['currentPerson', 'Spersons', 'Lpersons', 'Bpersons',]),
    },
    methods: {
        ...mapActions('Person', [
            'getPerson',
            'searchPersons',
            'listPersons',
            'benchPersons',
            'personsReset',
        ]),
        getGender(){
            return this.currentPerson.gender == 'W' ? 'venus' : 'mars';
        },
        getBirthReadable(){
            if(this.currentPerson.date_birth){
                moment.locale('fr'); 
                var momentObj = moment(this.currentPerson.date_birth, 'YYYY-MM-DD');
                return momentObj.format('dddd Do MMMM, YYYY');
            }
            return false;
        }
    },
}
