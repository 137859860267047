<template>
  <div class="user-menu" v-click-outside="doUnroll">
      <div @click="doRoll()" class="button-label px-2">
        <fa icon="user" />
        <div class="user-name">&nbsp;{{ profile.representation }}</div>
        <fa :icon="getCaret(unroll)" :key="unroll"/>
      </div>
      <nav class="open-menu" v-if="unroll">
        <div class="account-type py-1 px-1" v-if="isAuthenticated && profile.groups.includes('corporate')">
          <fa icon="briefcase" />&nbsp;{{ _tr("global.corporate", "Entreprise") }}
        </div>
        <div class="account-type py-1 px-1" v-if="isAuthenticated && !profile.groups.includes('corporate')">
          <fa icon="briefcase" />&nbsp;{{ _tr("global.executive", "Exécutif") }}
        </div>
        <a class="link-dashboard" :href="scalensAppUrl">
          <fa icon="home" />&nbsp;Dashboard
        </a>
        <a href="/admin/" v-if="profile.is_staff">
          <fa icon="tools" />&nbsp;{{ _tr("global.admin", "Admin") }}
        </a>
        <div class="auth-button py-1 px-1" @click="logout">
          <fa icon="sign-out-alt" />&nbsp;{{
            _tr("global.disconnect", "Déconnexion")
          }}
        </div>
      </nav>
  </div>
</template>

<script>
import user from "@/components/user/user.js";
import { mapActions } from "vuex";

export default {
  mixins: [user],
  methods: {
    ...mapActions("User", ["logout"]),
  },
  computed: {
    scalensAppUrl() {
      return process.env.VUE_APP_SCALENS_APP_URL;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/vars.scss";

.user-menu {
  @media (max-width: 1128px) {
    width: 65px;
  }
}

.user-name {
  @media (max-width: 1128px) {
    display: none;
  }
}

.button-label {
  display: flex;
  align-items: center;
  min-width: 150px;
  white-space: nowrap;
  gap: 5px;
  
  @media (max-width: 1128px) {
    display: inline-block;
    min-width: 30px;
  }
}

.open-menu {
  white-space: nowrap;
  margin-top: -1px; // fix gap on mobile

  @media (max-width: 1128px) {
    translate: -75px;
  }
}

.account-type {
  cursor: default;

  @media (max-width: 1149px) {
    display: block;
  }
}

.link-dashboard {
  @media (min-width: 407px) {
    display: none !important;
  }
}

.auth-button {
  > a {
    color: white;
    text-decoration: none;
  }
  
  > a:hover {
    color: $light-blue;
  }
}
  
</style>
