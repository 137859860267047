<template>
    <div v-click-outside="doUnroll">
        <div @click="doRoll()">
            <span>{{ _tr('global.library', 'Bibliothèque') }}&nbsp;</span>
            <fa :icon="getCaret(unroll)" :key="unroll"/>
        </div>
        <nav v-if="unroll && profile.groups.includes('corporate')">
            <router-link :to="{name: 'company-list'}">{{ _tr('global.lib-companies', 'Sociétés') }}</router-link>
            <router-link :to="{name: 'person-list'}">{{ _tr('global.lib-personalities', 'Personnes') }}</router-link>
            <router-link :to="{name: 'auditor-list'}">{{ _tr('global.lib-auditors', 'Auditeurs') }}</router-link>
            <router-link :to="{name: 'assembly-list'}">{{ _tr('global.lib-assemblies', 'Assemblées') }}</router-link>
        </nav>
        <nav v-else-if="unroll && !profile.groups.includes('corporate')">
            <router-link :to="{name: 'company-list'}">{{ _tr('global.lib-companies', 'Sociétés') }}</router-link>
            <router-link :to="{name: 'person-list'}">{{ _tr('global.lib-personalities', 'Personnes') }}</router-link>
        </nav>
    </div>
</template>
