import http from "@/api";

const state = {
    endpoint: '/api/person/',
    currentPerson: {},
    Spersons: {},
    Lpersons: {},
    Bpersons: {},
}

const mutations = {
    ['PERSON'](state, person){
        state.currentPerson = person;
    },
    ['SEARCH'](state, persons){
        state.Spersons = persons;
    },
    ['LIST'](state, persons){
        state.Lpersons = persons;
    },
    ['BENCHMARK'](state, persons){
        state.Bpersons = persons;
    }
}

const getters = {

}

const actions = {
    async getPerson({ state, commit }, uid){
        const response = await http.get(state.endpoint + uid + '/');
        commit('PERSON', response.data);
        return response;
    },
    async getPersons({ state }, payload){
        const response = await http.get(state.endpoint, payload);
        return response;
    },
    async getFullPersons({ state }, payload){
        const response = await http.get(state.endpoint + 'full/', payload);
        return response;
    },
    async searchPersons({ commit, dispatch }, payload){
        const response = await dispatch('getPersons', payload);
        commit('SEARCH', response.data);
        return response;
    },
    async listPersons({ commit, dispatch }, payload){
        const response = await dispatch('getFullPersons', payload);
        commit('LIST', response.data);
        return response;
    },
    async benchPersons({ commit, dispatch }, payload){
        const response = await dispatch('getPersons', payload);
        commit('BENCHMARK', response.data);
        return response;
    },
    async nextPersons({ state, commit }){
        if(state.Bpersons.next) {
            const response = await http.get(state.Bpersons.next);
            commit('BENCHMARK', response.data);
            return response;
        }
    },
    async previousPersons({ state, commit }){
        if(state.Bpersons.previous) {
            const response = await http.get(state.Bpersons.previous);
            commit('BENCHMARK', response.data);
            return response;
        }
    },
    personsReset({ commit }, mut){
        commit(mut, {});
    }

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
