<template>
    <Item class="company-item" :class="{small: small}" :imageUrl="person.image_url">
        <component class="m-0" :is="tag">
            <span>{{ person.fullname }}&nbsp;</span>
            <small v-if="person.age">({{ person.age }}&nbsp;{{ _tr("person.age", "ans") }})</small>
        </component>
    </Item>
</template>

<script>
import Item from '@/tools/Item.vue';
import person from "@/components/person/person.js";

export default {
    mixins: [person],
    components: {
        Item
    },
    props: {
        person:  { type: Object, required: true },
        tag:     { type: String, default: 'h2' },
        small:   { type: Boolean },
    },
}
</script>

<style lang="scss">
@import "@/assets/vars.scss";
</style>