import http from "@/api";

const state = {
    endpoint: '/api/home/',
    news: [],

}

const mutations = {
    ['NEWS'](state, news){
        state.news = news;
    }
}

const getters = {

}

const actions = {
    async getOneNews({ state, commit }, uid){
        const response = await http.get(state.endpoint + uid + '/');
        commit('PERSON', response.data);
        return response;
    },
    async getNews({ state }, payload){
        const response = await http.get(state.endpoint, payload);
        return response;
    },
    async listNews({ commit, dispatch }, payload){
        const response = await dispatch('getNews', payload);
        commit('NEWS', response.data);
        return response;
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
