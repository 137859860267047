<template>
  <div class="bdata-container">
    <div class="global-result d-lg-flex">
      <div class="results flex-fill" v-if="hasResults(Scompanies)">
        <router-link
          class="result d-block m-2"
          v-for="company in Scompanies.results"
          :key="company.uid"
          :to="{ name: 'company-detail', params: { companyUid: company.uid } }"
        >
          <company-item :company="company" small />
        </router-link>
        <div class="pt-2" />
      </div>
      <div class="results flex-fill" v-if="hasResults(Spersons)">
        <router-link
          class="result d-block m-2"
          v-for="person in Spersons.results"
          :key="person.uid"
          :to="{ name: 'person-detail', params: { personUid: person.uid } }"
        >
          <person-item :person="person" small />
        </router-link>
        <div class="pt-2" />
      </div>
    </div>
  </div>
</template>

<script>
import CompanyItem from "@/components/company/utils/CompanyItem.vue";
import PersonItem from "@/components/person/utils/PersonItem.vue";
import company from "@/components/company/company.js";
import person from "@/components/person/person.js";

export default {
  mixins: [company, person],
  components: {
    CompanyItem,
    PersonItem,
  },
  methods: {
    hasResults(objs) {
      return this.has(objs) && objs.results.length;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/vars.scss";

.global-result {
  z-index: 75;
  width: 100%;
  position: fixed;
  max-height: 300px;
  overflow: auto;
  max-width: 1500px;
  background-color: $primary;
  top: 60px;

  > .results {
    > .result {
      text-decoration: none;
      border-radius: 15px;
      background-color: $dh-green;
    }
    > .result:hover {
      background-color: $panel-color;
    }
  }
}
</style>
