<template>
  <div class="shadow vw-100 vh-100 d-flex flex-column align-items-stretch" v-if="userReady">
    <Menu />
    <div class="overflow-auto h-100">
      <router-view/>
    </div>
  </div>
</template>

<script>
import Menu from "./components/menu/Menu.vue";

export default {
  name: "App",
  data() {
    return {
      userReady: false,
    };
  },
  components: {
    Menu,
  },
  async beforeCreate() {
    await this.$store.dispatch("User/verifyToken");
    await this.$store.dispatch("User/tr_");
    await this.$store.dispatch("User/config_");
    if (this.isAuthenticated) {
      await this.$store.dispatch("User/getProfile");
    }
    await this.$store.dispatch("User/max");
    this.userReady = true;
  },
};
</script>

<style lang="scss">
@import "@/assets/boarddata.scss";

</style>
