import http from "@/api";

const state = {
    endpoint: '/api/assembly/',
    currentCrisis: {},
    Scrisis: {},
    Lcrisis: {},
    Bcrisis: {},
    B2crisis: {},
    Bstats: {},
    B2stats: {},
}

const mutations = {
    ['PERSON'](state, crisis){
        state.currentCrisis = crisis;
    },
    ['SEARCH'](state, crisis){
        state.Scrisis = crisis;
    },
    ['LIST'](state, crisis){
        state.Lcrisis = crisis;
    },
    ['BENCHMARK'](state, crisis){
        state.Bcrisis = crisis;
    },
    ['BENCHMARK2'](state, crisis){
        state.B2crisis = crisis;
    },
    ['STATS'](state, stats){
        state.Bstats = stats;
    },
    ['STATS2'](state, stats){
        state.B2stats = stats;
    }
}

const getters = {

}

const actions = {
    async getCrisis({ state, commit }, uid){
        const response = await http.get(state.endpoint + uid + '/');
        commit('PERSON', response.data);
        return response;
    },
    
    async getCrisis({ state }, payload){
        const response = await http.get(state.endpoint, payload);
        return response;
    },
    async getStats({ state }, payload){
        const response = await http.get(state.endpoint+'stats/', payload);
        return response;
    },
    async getFullCrisis({ state }, payload){
        const response = await http.get(state.endpoint + 'full/', payload);
        return response;
    },
    
    async searchCrisis({ commit, dispatch }, payload){
        const response = await dispatch('getCrisis', payload);
        commit('SEARCH', response.data);
        return response;
    },
    async listCrisis({ commit, dispatch }, payload){
        const response = await dispatch('getFullCrisis', payload);
        commit('LIST', response.data);
        return response;
    },
    async benchCrisis({ commit, dispatch }, payload){
        const response = await dispatch('getFullCrisis', payload);
        commit('BENCHMARK', response.data);
        return response;
    },
    async bench2Crisis({ commit, dispatch }, payload){
        const response = await dispatch('getFullCrisis', payload);
        commit('BENCHMARK2', response.data);
        return response;
    },
    async statsCrisis({ commit, dispatch }, payload){
        const response = await dispatch('getStats', payload);
        commit('STATS', response.data);
        return response;
    },
    async stats2Crisis({ commit, dispatch }, payload){
        const response = await dispatch('getStats', payload);
        commit('STATS2', response.data);
        return response;
    },
    crisisReset({ commit }, mut){
        commit(mut, {});
    }

}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
